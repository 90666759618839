import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Ability Matters",
  pageDescription:
    "Learn how an agency for the mentally disabled uses SpotOn to promote awareness of its services and offer online recurring payments to its clients.",
  title: "Growing awareness for autism",
  subTitle: `How Ability Matters expanded its client base and payment options with SpotOn.`,
  businessName: "Ability Matters",
  industry: "Professional services",
  location: "Dublin, Ohio",
  products: [spotonProducts.WEBSITE, spotonProducts.PAYMENTS],
  description:
    "Ability Matters is an agency in Ohio that provides services for individuals with autism and other neurological disabilities. With the help of SpotOn, they’ve increased awareness of their programs and simplified the client payment process.",
  quoteData: {
    imgName: "ability-matters.png",
    quote: `“People from SpotOn take ownership. I never feel like I’m being passed around. When someone responds to me, I know that they’re going to help me—it’s not just a step
in the workflow for them.”`,
    personName: "Michael Deck",
    personTitle: "Systems Administrator, Ability Matters",
  },
  goal: `Ability Matters needed a platform where they could update their website and share forms with employees, clients, and the public. They were also looking for a way to accept and track online client payments.`,
  solution: `With SpotOn Website, Ability Matters was able to modernize their website and get more inquiries about their services. And with SpotOn Payments, clients could pay online and get email receipts instead of paying in person.`,
  results: {
    title: `The results`,
    stats: [
      { title: `Decreased`, description: `time spent on website maintenance` },
      {
        title: `Increased`,
        description: `inquiries due to a web presence`,
      },
      { title: `Improved.`, description: `payment processing and tracking` },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Imagining gift baskets online",
    imageName: "imagine-that.png",
    industry: resourceIndustries.RETAIL,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-17",
    href: "/case-studies/imagine-that",
    goals: [
      resourceGoals.CONNECT_AND_SELL_ONLINE,
      resourceGoals.REACH_NEW_CUSTOMERS,
    ],
  },
  {
    title: "Growing a flower business from the ground up",
    imageName: "flowers-on-phoenix.png",
    industry: resourceIndustries.RETAIL,
    tag: resourceTags.CASE_STUDY,
    date: "2022-07-28",
    href: "/case-studies/flowers-on-phoenix",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
      resourceGoals.BUILD_CUSTOMER_LOYALTY,
    ],
  },
  {
    title: "A blooming nursery gives customers a faster experience",
    imageName: "unique-plants-and-palms.png",
    industry: resourceIndustries.RETAIL,
    tag: resourceTags.CASE_STUDY,
    date: "2022-07-13",
    href: "/case-studies/unique-plants-and-palms",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
];
